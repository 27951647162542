import { NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { iconWarningMedium, SVGIconsRegistry } from '@recall2/icons';

import { Recall2ButtonPrimaryComponent } from '../../../buttons';
import { CardComponent } from '../../../card';
import { Recall2IconArrowRightComponent, Recall2IconUserComponent } from '../../../icons';
import { AppsLinkDirective } from '../../../navigation';
import { TitleSectionComponent } from '../../../title-section';
import { AdminPanelItem } from '../../models/admin-panel-item.model';

@Component({
  selector: 'app-admin-panel-item',
  standalone: true,
  templateUrl: './admin-panel-item.component.html',
  styleUrls: ['./admin-panel-item.component.scss'],
  imports: [
    NgIf,
    CardComponent,
    TitleSectionComponent,
    TranslateModule,
    Recall2ButtonPrimaryComponent,
    Recall2IconUserComponent,
    AppsLinkDirective,
    Recall2IconArrowRightComponent,
  ],
})
export class AdminPanelItemComponent implements OnInit {
  @Input() item: AdminPanelItem;

  titleKey: string;

  constructor(iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconWarningMedium]);
  }

  ngOnInit(): void {
    this.titleKey = `admin-panel.navigation-item.${this.item.key}.title`;
  }
}
