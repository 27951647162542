import { NgIf } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  iconNavigationDirectionDownSmall,
  iconNavigationDirectionUpDownSmall,
  iconNavigationDirectionUpSmall,
  SVGIconComponent,
  SVGIconsRegistry,
} from '@recall2/icons';

import type { FieldSorting } from '../../../core/api/models/sortable-request-options';
import { TableSortingOrder } from '../../models/table-sorting-order.enum';
import { getHeaderParentElement } from '../../utils/table-columns.utils';

@Component({
  selector: 'app-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.actionable-column]': 'true' },
  standalone: true,
  imports: [NgIf, SVGIconComponent],
})
export class TableSortComponent implements OnInit, OnChanges {
  readonly SortValues = TableSortingOrder;

  @Input() sortOrder = TableSortingOrder.None;
  @Input() column: string;

  @Output() readonly sortChange = new EventEmitter<{ sortOrder: TableSortingOrder; column: string }>();

  selectedColumn: string;
  currentSortOrder: TableSortingOrder;

  readonly activeSortClass = 'active-header-sorting-cell';

  constructor(
    iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    iconsRegistry.registerIcons([
      iconNavigationDirectionDownSmall,
      iconNavigationDirectionUpSmall,
      iconNavigationDirectionUpDownSmall,
    ]);
  }

  ngOnInit(): void {
    this.currentSortOrder = this.sortOrder;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['selectedColumn'] && this.column !== simpleChanges['selectedColumn'].currentValue) {
      this.currentSortOrder = TableSortingOrder.None;
    }
  }

  changeSortOrder(): void {
    if (this.currentSortOrder === TableSortingOrder.None) {
      this.currentSortOrder = TableSortingOrder.Asc;
    } else if (this.currentSortOrder === TableSortingOrder.Asc) {
      this.currentSortOrder = TableSortingOrder.Desc;
    } else {
      this.currentSortOrder = TableSortingOrder.None;
    }

    this.sortChange.emit({ sortOrder: this.currentSortOrder, column: this.column });
  }

  updateSelected(sortChange: FieldSorting): void {
    this.selectedColumn = sortChange?.field;
    const parentElement = getHeaderParentElement(this.elementRef);

    if (this.column === this.selectedColumn) {
      this.currentSortOrder = sortChange.orderBy as TableSortingOrder;
      if (parentElement) {
        parentElement.classList.add(this.activeSortClass);
      }
    } else {
      this.currentSortOrder = TableSortingOrder.None;
      this.cdr.detectChanges();
      if (parentElement) {
        parentElement.classList.remove(this.activeSortClass);
      }
    }
  }
}
