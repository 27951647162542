import { OverlayModule } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconClearMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import type { AutoSuggestTag } from '../../model';
import { Recall2ChipComponent } from '../recall2-chip/recall2-chip.component';
import { FilterAutoSuggestPipe } from './pipes/filter-autosuggest.pipe';

@Component({
  selector: 'recall2-tags',
  standalone: true,
  templateUrl: './recall2-tags.component.html',
  styleUrls: ['./recall2-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    OverlayModule,
    MatTooltipModule,
    TranslateModule,
    SVGIconComponent,
    Recall2IconHelpComponent,
    Recall2ChipComponent,
    FilterAutoSuggestPipe,
  ],
})
export class Recall2TagsComponent {
  @Input() title: string;
  @Input() placeholder?: string;
  @Input() tooltip?: string;
  @Input() currentTags: string[] = [];
  @Input() currentTagsObjects: { id: string; content: string; value?: string; label?: string }[] = [];
  @Input() useInput = true;
  @Input() showBorder = false;
  @Input() isReadOnly = false;
  @Input() autoSuggestTags: AutoSuggestTag[] = null;
  @Input() showAutoSuggest = false;
  @Input() maxLength = 60;
  @Input() isMoreFilters: boolean;
  @Output() updateTags = new EventEmitter<string[]>();
  @Output() removedTagId = new EventEmitter<number>();
  @Output() searchTags = new EventEmitter<string>();

  readonly separatorKeys: string[] = ['Enter', ','];
  inputTextFocused: boolean;
  isAutoSuggestOpen = false;
  formFieldChipsWidth = 100;
  inputText: string;

  @ViewChild('formFieldChips')
  formFieldChips: ElementRef;

  @ViewChild('input')
  input: ElementRef;

  constructor(
    private iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
  ) {
    this.iconsRegistry.registerIcons([iconClearMedium]);
  }

  private keyIsNoSeparatorKey(event: KeyboardEvent): boolean {
    return !this.separatorKeys.includes(event.key);
  }

  private tagDoesNotExist(value: string): boolean {
    return !this.currentTags.some(tag => tag.toLowerCase() === value.toLowerCase());
  }

  private toggleAutoSuggest(open: boolean): void {
    this.isAutoSuggestOpen = open;
    this.cdr.markForCheck();
  }

  private resetInput(): void {
    if (this.useInput) {
      delete this.inputText;
      this.input.nativeElement.value = '';
      this.input.nativeElement.blur();
      this.cdr.markForCheck();
    }
  }

  addTagWithEnter(event: KeyboardEvent): void {
    if (this.keyIsNoSeparatorKey(event)) {
      return;
    }

    this.addTagFromInput();

    event.preventDefault();
  }

  addTagFromInput(): void {
    const value = this.input.nativeElement.value.trim().replaceAll(',', '');

    if (value === '') {
      return;
    }

    if (this.tagDoesNotExist(value)) {
      this.addTag(value);
    } else {
      this.resetInput();
    }

    this.cdr.markForCheck();
  }

  addTag(newTag: string): void {
    this.currentTags = [...this.currentTags, newTag];
    this.toggleAutoSuggest(false);
    this.updateTags.emit(this.currentTags);
    this.resetInput();
    this.cdr.markForCheck();
  }

  remove(currentTag: string): void {
    const tagIndex = this.currentTags.indexOf(currentTag);
    this.currentTags = this.currentTags.filter(tag => tag !== currentTag);
    this.updateTags.emit(this.currentTags);
    this.removedTagId.emit(tagIndex);
    this.toggleAutoSuggest(false);
    this.resetInput();
    this.cdr.markForCheck();
  }

  removeAll(event: Event): void {
    this.currentTags = [];
    this.updateTags.emit(this.currentTags);
    this.toggleAutoSuggest(false);
    this.resetInput();
    event.stopPropagation();
    this.cdr.markForCheck();
  }

  isInputFocused(focused: boolean): void {
    this.inputTextFocused = focused;

    if (focused) {
      this.toggleAutoSuggest(true);
    }

    if (this.showAutoSuggest) {
      this.formFieldChipsWidth = this.formFieldChips.nativeElement.offsetWidth;
    }

    this.onInputKeyPress();

    if (!focused && !this.showAutoSuggest) {
      this.addTagFromInput();
    }

    this.cdr.markForCheck();
  }

  autoFocus(): void {
    if (!this.inputTextFocused) {
      this.input.nativeElement.focus();
    }
  }

  onBackdropClick(): void {
    if (this.isAutoSuggestOpen) {
      this.toggleAutoSuggest(!this.isAutoSuggestOpen);
    }
  }

  onInputKeyPress(): void {
    const charWidth = this.input.nativeElement.value.length + 2;
    this.input.nativeElement.style.width = `${charWidth}ch`;
  }

  search(): void {
    if (this.showAutoSuggest && this.inputText?.length) {
      this.autoSuggestTags = null;
      this.searchTags.emit(this.inputText);
      this.cdr.markForCheck();
    }
  }
}
