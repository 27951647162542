import type { ComponentType } from '@angular/cdk/overlay';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import type { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import type { CommonModalData } from '../../models/common-modal-data.model';

@Injectable({
  providedIn: 'root',
})
export class CommonModalService {
  private readonly defaultConfig: MatDialogConfig<CommonModalData> = {
    panelClass: 'app-modal',
    width: '560px',
    minHeight: null,
    autoFocus: false,
    backdropClass: 'modal-backdrop',
    scrollStrategy: new NoopScrollStrategy(),
    data: {
      title: 'cancelModal.title',
      content: 'cancelModal.content',
      cancelButtonKey: 'common.cancel',
      submitButtonKey: 'common.save',
      isDelete: false,
    },
  } as const;

  constructor(private readonly dialog: MatDialog) {}

  open<TComponent, TData = any, TReturn = boolean>(
    component: ComponentType<TComponent>,
    modalConfig?: Partial<MatDialogConfig<Partial<CommonModalData<TData>>>>,
  ): MatDialogRef<TComponent, TReturn> {
    return this.dialog.open<TComponent, CommonModalData<TData>, TReturn>(component, {
      ...this.defaultConfig,
      ...modalConfig,
      data: {
        ...this.defaultConfig.data,
        ...modalConfig?.data,
      },
    });
  }

  closeAll(): void {
    this.dialog.closeAll();
  }
}
