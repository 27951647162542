import { AsyncPipe, NgFor } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { iconSearchMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';

import { CardComponent } from '../card';
import type { FeatureFlag } from '../feature-flag';
import { CONSUMER_MANAGEMENT_APP_FEATURE_FLAG, Recall2FeatureFlagService } from '../feature-flag';
import { Recall2InputTextComponent } from '../form/components/recall2-input-text';
import { InputTextProperty } from '../form/model';
import type { ERole } from '../navbar';
import { hasObjectReadAccess } from '../navbar/helper/helper-function';
import { ENavbarItem } from '../navbar/models/navbar-item.model';
import { URL_PATHS } from '../navbar/url-paths';
import { App } from '../navigation';
import { TitleSectionComponent } from '../title-section';
import { AdminPanelItemComponent } from './components/admin-panel-item/admin-panel-item.component';
import { GET_ADMIN_PANEL_USER_ROLES } from './injection-tokens/admin-panel-user-roles.token';
import type { AdminPanelItem } from './models/admin-panel-item.model';

export const TYPING_DEBOUNCE_TYPE = 300;

@Component({
  selector: 'recall2-admin-panel',
  standalone: true,
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  imports: [
    NgFor,
    TranslateModule,
    CardComponent,
    TitleSectionComponent,
    AdminPanelItemComponent,
    Recall2InputTextComponent,
    SVGIconComponent,
    AsyncPipe,
  ],
})
export class AdminPanelComponent implements OnDestroy, OnInit {
  private applicationSearchTerm = new BehaviorSubject<string>('');
  private filteredPanelItems = new BehaviorSubject<AdminPanelItem[]>([]);
  filteredPanelItems$ = this.filteredPanelItems.asObservable();

  searchProperty: InputTextProperty;

  private visiblePanelItems: AdminPanelItem[] = [];
  private destroyed$ = new Subject<void>();
  private lastSearchTerm = '';
  private currentUserRoles: ERole[];

  constructor(
    @Inject(GET_ADMIN_PANEL_USER_ROLES) private userRoles: Observable<ERole[]>,
    private featureService: Recall2FeatureFlagService,
    private translateService: TranslateService,
    private iconsRegistry: SVGIconsRegistry,
  ) {
    this.iconsRegistry.registerIcons([iconSearchMedium]);
  }

  ngOnInit(): void {
    this.userRoles.subscribe((userRoles: ERole[]) => {
      this.currentUserRoles = userRoles;
      this.loadNavigationItems();
    });

    this.initSubscriptions();
    this.initSearch();
  }

  private loadNavigationItems(): void {
    const navigationItems: AdminPanelItem[] = [
      {
        key: 'user-registration',
        icon: 'warning',
        menuItem: {
          name: 'user-management-app-proposal-request',
          innerHTML: this.translateService.instant('navigation.menu-main.user-management.proposal-request'),
          targetApp: App.UserManagement,
          urlPath: URL_PATHS.USERMGMT_APP_PROPOSAL_REQUEST,
          isVisible: this.isItemVisible(ENavbarItem.USER_MANAGEMENT_SUB_ADMIN),
        },
      },
      {
        key: 'users',
        icon: 'warning',
        menuItem: {
          name: 'user-management-app-user',
          innerHTML: this.translateService.instant('navigation.menu-main.user-management.users'),
          targetApp: App.UserManagement,
          urlPath: URL_PATHS.USERMGMT_APP_USERS,
          isVisible: this.isItemVisible(ENavbarItem.USER_MANAGEMENT_SUB_ADMIN),
        },
      },
      {
        key: 'blocked-campaigns',
        icon: 'warning',
        menuItem: {
          name: 'user-management-blocked-campaigns',
          innerHTML: this.translateService.instant('navigation.menu-main.blocked-campaigns'),
          targetApp: App.Campaigns,
          urlPath: URL_PATHS.ADMIN_CONSOLE_BLOCKED_CAMPAIGN_IDS,
          isVisible: this.isItemVisible(ENavbarItem.USER_MANAGEMENT_SUB_ADMIN_ROOT),
        },
      },
      {
        key: 'consumer-management',
        icon: 'warning',
        menuItem: {
          name: 'consumer-management',
          innerHTML: this.translateService.instant('navigation.menu-main.consumer-management'),
          targetApp: App.ConsumerManagement,
          urlPath: URL_PATHS.CONSUMER_MANAGEMENT,
          isVisible: this.isItemVisible(ENavbarItem.CONSUMER_MANAGEMENT, CONSUMER_MANAGEMENT_APP_FEATURE_FLAG),
        },
        quickAction: {
          name: 'create-consumer-management',
          innerHTML: this.translateService.instant('admin-panel.quick-actions.consumer-management.create'),
          targetApp: App.ConsumerManagement,
          urlPath: URL_PATHS.CONSUMER_MANAGEMENT_CREATE,
          isVisible: this.isItemVisible(ENavbarItem.CONSUMER_MANAGEMENT, CONSUMER_MANAGEMENT_APP_FEATURE_FLAG),
        },
      },
    ];
    this.visiblePanelItems = navigationItems.filter(item => item.menuItem.isVisible);
  }

  private initSubscriptions(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadNavigationItems();
      this.applicationSearchTerm.next(this.lastSearchTerm);
    });

    this.applicationSearchTerm.pipe(takeUntil(this.destroyed$)).subscribe((searchTerm: string) => {
      const panelItems = this.getFilteredPanelItems(searchTerm);
      this.filteredPanelItems.next(panelItems);
    });
  }

  private getFilteredPanelItems(searchTerm: string): AdminPanelItem[] {
    if (!searchTerm.trim()) {
      return this.visiblePanelItems;
    }

    return this.visiblePanelItems.filter(
      item => item.menuItem?.innerHTML.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  private isItemVisible(navbarItem: ENavbarItem, feature?: FeatureFlag): boolean {
    if (feature && !this.featureService.isActive(feature)) {
      return false;
    }

    return hasObjectReadAccess(navbarItem, this.currentUserRoles);
  }

  private initSearch(): void {
    this.searchProperty = new InputTextProperty(
      'admin-panel-search-applications',
      false,
      'admin-panel.main.search-applications',
      null,
      false,
      new FormControl(),
      false,
    );

    this.searchProperty.control.valueChanges
      .pipe(debounceTime(TYPING_DEBOUNCE_TYPE), distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(value => {
        this.applicationSearchTerm.next(value);
        this.lastSearchTerm = value;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
