<a class="header-logo" data-testid="header-logo">
  <div class="icon-div">
    <recall2-icon-logo-recall
      class="nav-header-lg"
      [rc2IconStyles]="'i-background i-40'"
      [attr.data-testid]="'my-data-menu-profile'"
    >
    </recall2-icon-logo-recall>
  </div>
  <span id="navbar-title" class="nav-header-lg">{{ 'header.recall-logo.text' | translate }}</span>
</a>

<div class="header-nav-container">
  <ng-container *ngIf="hasVinSearchPermissions">
    <div class="header-action rc2-display--flex rc2-align-items--center rc2-justify--center rc2-mar--right--xl">
      <a recall2AppsLink [attr.data-testid]="'navigate-vins'" [targetApp]="APPS.Campaigns" [urlPath]="VIN_SEARCH_URL">
        <div
          class="button-modal rc2-display--flex-column rc2-align-items--center rc2-justify--center rc2-pad--top--xs rc2-pad--bottom--3xs rc2-pad--left--s rc2-pad--right--s rc2-cursor--pointer"
          id="navigate-vins-link"
          data-cy="navigate-vins-button"
        >
          <recall2-svg-icon name="car" size="medium" class="rc2-icon--color--white rc2-pad--right--xxs">
          </recall2-svg-icon>
          <p class="rc2-font--size--12">{{ 'nav-main.search-vins' | translate }}</p>
        </div>
      </a>
    </div>
  </ng-container>

  <div
    class="select-language header-action rc2-display--flex rc2-align-items--center rc2-justify--center rc2-mar--right--xl"
  >
    <div
      class="button-modal rc2-display--flex rc2-align-items--center rc2-justify--center rc2-pad--left--s rc2-pad--right--s rc2-cursor--pointer"
      id="button-modal"
      data-cy="language-selector-modal"
      (click)="openLanguageSelectorModal()"
    >
      <recall2-svg-icon name="stroke" size="medium" class="rc2-icon--color--white rc2-pad--right--xxs">
      </recall2-svg-icon>
      <p class="rc2-font--size--16">{{ 'header.language-modal.' + language | translate }}</p>
    </div>
  </div>

  <div class="icon-confidential rc2-mar--right--xl" data-testid="icon-confidential">
    <recall2-icon-confidential></recall2-icon-confidential>
  </div>

  <recall2-my-data-menu *ngIf="!hideProfile && hasRoles" class="header-action header-icon" (logout)="onLogout()">
  </recall2-my-data-menu>

  <div *ngIf="!hideProfile && !hasRoles" class="header-action header-icon" (click)="onLogout()">
    <recall2-icon-close class="rc2-icon--size--26"></recall2-icon-close>
    <div
      class="header-action-text rc2-font--size--12 rc2-font--family--bold rc2-text-align--center rc2-line--height--15 rc2-mar--top--3xs"
    >
      {{ 'nav-main.my-data-menu.logout' | translate }}
    </div>
  </div>
</div>
