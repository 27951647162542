import type { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

import type { Download } from '../../../../../core/models/download.model';
import type { IRecall2FilterItem } from '../../../../../overlay/models/filter.model';
import { IRecall2Comparator } from '../../../../../overlay/models/filter.model';
import type { DownloadRequestOptions } from '../../models/download-request-options.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private readonly http: HttpClient) {}

  private readonly comparatorMap = {
    [IRecall2Comparator.EQUAL]: ':',
    [IRecall2Comparator.GREATER]: '>',
    [IRecall2Comparator.GREATER_EQUAL]: '>=',
    [IRecall2Comparator.LOWER]: '<',
  };

  downloadFileQueryParams(url: string, sort: string, filter: string): Observable<Download> {
    let params = new HttpParams({});

    if (sort) {
      params = params.append('sort', sort);
    }

    if (filter) {
      params = params.append('search', filter);
    }

    return this.http
      .get<Blob>(url, { params, reportProgress: true, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        delay(1000),
        map(res => this.mapResponse(res)),
        catchError(error => throwError(() => error)),
      );
  }

  downloadFileGET(url: string, options: DownloadRequestOptions = {}): Observable<Download> {
    let params = new HttpParams({ fromObject: options.extraParams || {} });
    const search = this.concatFilters(options.filter || []);

    if (options.sort) {
      for (const item of options.sort) {
        params = params.append('sort', `${item.identifier},${item.value}`);
      }
    }

    if (search) {
      params = params.append('search', search);
    }

    return this.http
      .get<Blob>(url, { params, reportProgress: true, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(
        delay(1000),
        map(res => this.mapResponse(res)),
        catchError(error => throwError(() => error)),
      );
  }

  downloadFilePOST(url: string, options: DownloadRequestOptions = {}): Observable<Download> {
    return this.http
      .post<Blob>(url, options, {
        reportProgress: true,
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        delay(1000),
        map(res => this.mapResponse(res)),
        catchError(error => throwError(() => error)),
      );
  }

  private concatFilters(filters: IRecall2FilterItem[]): string {
    return filters
      .map(filter => `${filter.identifier}${this.comparatorMap[filter.comparator]}${filter.value}`)
      .join(';');
  }

  private mapResponse(response: HttpResponse<Blob>): Download {
    return {
      content: new Blob([response.body], { type: 'blob' }),
      filename: this.getFileName(response.headers.get('content-disposition')),
      size: response.body.size,
      url: response.url,
      type: response.body.type,
    };
  }

  private getFileName(headerContentDisposition: string): string {
    let filename: string;
    try {
      filename = headerContentDisposition.split(';')[1].split('filename=')[1].trim();
    } catch {
      filename = 'data.csv';
    }
    return filename;
  }
}
