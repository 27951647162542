import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  iconCorrectMedium,
  iconCorrectSmall,
  iconStatusTooltipMedium,
  iconStatusTooltipSmall,
  iconWarningMedium,
  iconWarningSmall,
  SVGIconComponent,
  SVGIconsRegistry,
} from '@recall2/icons';

import { NotificationType } from '../../enums/notification.enum';

const iconNameMap: { [key in NotificationType]: string } = {
  success: 'correct',
  warning: 'warning',
  error: 'warning',
  info: 'status-tooltip',
  download: 'download',
};

@Component({
  selector: 'app-notification-icon',
  templateUrl: './notification-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SVGIconComponent],
})
export class NotificationIconComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  private notificationType: NotificationType;

  @Input()
  set type(notificationType: NotificationType) {
    this.notificationType = notificationType;
    this.iconName = iconNameMap[notificationType];
  }
  get type(): NotificationType {
    return this.notificationType;
  }

  iconName: string;

  constructor(private iconsRegistry: SVGIconsRegistry) {
    this.iconsRegistry.registerIcons([
      iconCorrectSmall,
      iconCorrectMedium,
      iconStatusTooltipSmall,
      iconStatusTooltipMedium,
      iconWarningSmall,
      iconWarningMedium,
    ]);
  }
}
