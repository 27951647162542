import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { iconDownloadMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import { FileSizePipe } from '@recall2/ui/pipes';

import type { AttachmentFile } from '../../../../models';

@Component({
  selector: 'app-table-attachments-list',
  templateUrl: './table-attachments-list.component.html',
  styleUrls: ['./table-attachments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, SVGIconComponent, TranslateModule, FileSizePipe],
})
export class TableAttachmentsListComponent {
  attachmentFiles: AttachmentFile[] = [];
  totalSize = 0;

  @Input() set files(files: AttachmentFile[]) {
    this.updateFiles(files);
  }

  @Output() fileClicked = new EventEmitter<AttachmentFile>();
  @Output() allFilesOptionClicked = new EventEmitter<void>();

  constructor(private _iconsRegistry: SVGIconsRegistry) {
    this._iconsRegistry.registerIcons([iconDownloadMedium]);
  }

  private updateFiles(files: AttachmentFile[]): void {
    this.attachmentFiles = files;
    this.totalSize =
      this.attachmentFiles.length > 0
        ? this.attachmentFiles.map(file => file.size).reduce((previousSize, currentSize) => previousSize + currentSize)
        : 0;
  }

  onAllFilesOptionClicked(): void {
    this.allFilesOptionClicked.emit();
  }

  onSingleFileClicked(file: AttachmentFile): void {
    this.fileClicked.emit(file);
  }
}
