import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import type { SupportedLanguages } from '../../../i18n/enums/language-code.enum';
import { LanguageCode } from '../../../i18n/enums/language-code.enum';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationService {
  static readonly USER_LANGUAGE_KEY: string = 'userLanguage';

  static readonly AVAILABLE_LANGUAGES: SupportedLanguages[] = [
    LanguageCode.German,
    LanguageCode.Spanish,
    LanguageCode.EnglishGB,
  ];
  static readonly ENGLISH_FALLBACK_LANGUAGE: SupportedLanguages = LanguageCode.EnglishGB;

  private _userLanguage = this.get();
  private readonly languages: SupportedLanguages[] = [
    LanguageCode.German,
    LanguageCode.Spanish,
    LanguageCode.EnglishGB,
  ];

  currentLanguage$ = new BehaviorSubject<SupportedLanguages>(LanguageCode.German);

  constructor(private _translateService: TranslateService) {}

  init(language?: SupportedLanguages): void {
    if (language) {
      this.use(language);
      return;
    }
    const userLang = this.getLanguageFromLocalStorage();
    const langToUse = userLang ?? this.getFallbackLanguage();

    this.use(langToUse);
  }

  async initAsync(language?: SupportedLanguages): Promise<void> {
    if (language) {
      await this.useAsync(language);
      return;
    }
    const userLang = this.getLanguageFromLocalStorage();
    const langToUse = userLang ?? this.getFallbackLanguage();

    await this.useAsync(langToUse);
  }

  getLanguageFromLocalStorage(): SupportedLanguages | null {
    const storedLang = window.localStorage.getItem(AppTranslationService.USER_LANGUAGE_KEY);
    if (storedLang && this.isSupportedLanguage(storedLang)) {
      return storedLang as SupportedLanguages;
    }
    return null;
  }

  isSupportedLanguage(lang: string): lang is SupportedLanguages {
    return AppTranslationService.AVAILABLE_LANGUAGES.includes(lang as SupportedLanguages);
  }

  switchTo(supportedLanguage: SupportedLanguages): void {
    return this.use(supportedLanguage);
  }

  getLanguages(): SupportedLanguages[] {
    return this.languages;
  }

  get(): SupportedLanguages {
    if (this._userLanguage) {
      return this._userLanguage;
    }
    const lang = this.getLanguageFromLocalStorage();
    return lang || this.getFallbackLanguage();
  }

  private use(language: SupportedLanguages): void {
    this.currentLanguage$.next(language);
    this._translateService.use(language);
    this._translateService.setDefaultLang(language);
    // save the Locale into the localStorage to keep the language even on refreshes
    window.localStorage.setItem(AppTranslationService.USER_LANGUAGE_KEY, language);
  }

  private async useAsync(language: SupportedLanguages): Promise<void> {
    this.currentLanguage$.next(language);
    await lastValueFrom(this._translateService.use(language));
    this._translateService.setDefaultLang(language);
    window.localStorage.setItem(AppTranslationService.USER_LANGUAGE_KEY, language);
  }

  private getFallbackLanguage(): SupportedLanguages {
    const browserLanguages = window.navigator.languages || [window.navigator.language];
    const fallbackLanguage = browserLanguages.find(lang =>
      AppTranslationService.AVAILABLE_LANGUAGES.includes(lang as SupportedLanguages),
    );

    return (fallbackLanguage as SupportedLanguages) ?? LanguageCode.EnglishGB;
  }
}
