import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import type { FeatureFlag } from '../../feature-flag/models/feature-flag.model';
import { Recall2FeatureFlagService } from '../../feature-flag/services/feature-flag.service';
import { App } from '../../navigation/models/app';
import { AppsService } from '../../navigation/services/apps.service';
import { hasObjectReadAccess, isGrpUser } from '../helper/helper-function';
import type { MeasureableMenuItem } from '../models/measureable-menu-item.model';
import { ENavbarItem } from '../models/navbar-item.model';
import type { ResponsiveMenuSplit } from '../models/responsive-menu-split.model';
import type { ERole } from '../models/role.model';
import { URL_PATHS } from '../url-paths';

@Injectable({ providedIn: 'root' })
export class ResponsiveMenuService {
  private readonly translateService = inject(TranslateService);
  private readonly featureService = inject(Recall2FeatureFlagService);
  private readonly appsService = inject(AppsService);

  readonly OFFSET = 230; // padding left and right
  readonly ABBREVIATION_MENU_ITEM_SIZE = 120;
  splitSubject$ = new BehaviorSubject<ResponsiveMenuSplit>({
    visibleMenuItems: [],
    hiddenMenuItems: [],
  });
  public splitSubject = this.splitSubject$.asObservable();

  private _visibleMenuItems: MeasureableMenuItem[] = [];
  private _roles: ERole[] = null;
  private _windowWidth = -1;

  static calculateRequiredWidth(menuItems: MeasureableMenuItem[]): number {
    // cannot be in-lined. see: https://github.com/ng-packagr/ng-packagr/issues/696
    // noinspection UnnecessaryLocalVariableJS
    const result = menuItems.map(item => item.width).reduce((a, b) => a + b, 0);
    return result;
  }

  getVisibleMenuItems(isHomeVisible = true): MeasureableMenuItem[] {
    const menu: MeasureableMenuItem[] = [
      {
        name: 'dashboard-menu-entry-home',
        innerHTML: '<i class="icon icon-i60-home rc2-icon--size--20"></i>',
        targetApp: App.Preliminary,
        urlPath: URL_PATHS.NOTIFICATION_APP_WELCOME,
        // Even users without roles can add a notification
        isVisible: isHomeVisible,
        ignoreActive: [{ targetApp: App.Preliminary, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'overview-menu-entry-home',
        innerHTML: this.translateService.instant('navigation.menu-main.notification'),
        targetApp: App.Preliminary,
        urlPath: URL_PATHS.NOTIFICATION_APP_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.PRELIMINARY),
        ignoreActive: [{ targetApp: App.Preliminary, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'topic-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.topic'),
        targetApp: App.Topics,
        urlPath: URL_PATHS.TOPIC_APP_OVERVIEW,
        isVisible: hasObjectReadAccess(ENavbarItem.TOPIC, this._roles),
        ignoreActive: [{ targetApp: App.Topics, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'clearing-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.clearing'),
        targetApp: App.Topics,
        urlPath: URL_PATHS.TOPIC_APP_CLEARING_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.CLEARING),
        ignoreActive: [{ targetApp: App.Topics, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'committee-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.committee'),
        targetApp: App.Committee,
        urlPath: URL_PATHS.COMMITTEE_APP_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.COMMITTEE),
        ignoreActive: [{ targetApp: App.Committee, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'meeting-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.meeting'),
        targetApp: App.Committee,
        urlPath: URL_PATHS.COMMITTEE_APP_MEETING_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.MEETING),
        ignoreActive: [{ targetApp: App.Committee, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'agenda-item-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.agenda-item'),
        targetApp: App.Committee,
        urlPath: URL_PATHS.COMMITTEE_APP_AGENDA_ITEM_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.AGENDA_ITEM),
        ignoreActive: [{ targetApp: App.Committee, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'verificationTask-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.verificationTask'),
        targetApp: App.Topics,
        urlPath: URL_PATHS.TOPIC_APP_VERIFICATION_TASK_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.VERIFICATION_TASK),
        ignoreActive: [{ targetApp: App.Topics, urlPath: URL_PATHS.ADMIN_PANEL }],
      },
      {
        name: 'campaign-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.campaign'),
        targetApp: App.Campaigns,
        urlPath: URL_PATHS.CAMPAIGN_APP_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.CAMPAIGN),
        ignoreActive: [
          { targetApp: App.Campaigns, urlPath: URL_PATHS.ADMIN_CONSOLE_BLOCKED_CAMPAIGN_IDS },
          { targetApp: App.Campaigns, urlPath: URL_PATHS.ADMIN_PANEL },
        ],
      },
      ...this.getCoordinationItems(),
      {
        name: 'partnerData-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.partnerData'),
        targetApp: App.PartnerData,
        urlPath: URL_PATHS.PARTNER_DATA_APP_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.PARTNER_DATA),
        ignoreActive: [{ targetApp: App.PartnerData, urlPath: URL_PATHS.ADMIN_PANEL }],
        children: [
          {
            name: 'partnerData-app-importers',
            innerHTML: this.translateService.instant('navigation.menu-main.partnerData.importer'),
            targetApp: App.PartnerData,
            urlPath: URL_PATHS.PARTNER_DATA_APP_OVERVIEW,
            isVisible: this.isNavbarItemVisible(ENavbarItem.PARTNER_DATA),
          },
        ],
      },
      {
        name: 'admin-panel-app-home',
        innerHTML: this.translateService.instant('navigation.menu-main.admin-panel.admin-console'),
        targetApp: this.appsService.getCurrentApp(),
        urlPath: URL_PATHS.ADMIN_PANEL,
        isVisible: this.isNavbarItemVisible(ENavbarItem.ADMIN_PANEL),
        forceActive: [
          { targetApp: this.appsService.getCurrentApp(), urlPath: URL_PATHS.ADMIN_PANEL },
          { targetApp: App.UserManagement, urlPath: URL_PATHS.USERMGMT_APP_PROPOSAL_REQUEST },
          { targetApp: App.UserManagement, urlPath: URL_PATHS.USERMGMT_APP_USERS },
          { targetApp: App.Campaigns, urlPath: URL_PATHS.ADMIN_CONSOLE_BLOCKED_CAMPAIGN_IDS },
        ],
      },
      !isGrpUser(this._roles) && {
        name: 'pending-completion-codes-overview',
        innerHTML: this.translateService.instant('navigation.menu-main.pending-completion-codes'),
        targetApp: App.Coordination,
        urlPath: URL_PATHS.PENDING_COMPLETION_CODES_OVERVIEW,
        isVisible: this.isNavbarItemVisible(ENavbarItem.PENDING_COMPLETION_CODES),
        ignoreActive: [
          { targetApp: App.Coordination, urlPath: URL_PATHS.ADMIN_PANEL },
          { targetApp: App.Coordination, urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES },
        ],
      },
    ];

    return menu
      .filter(item => item.isVisible)
      .map(menu => {
        const children = (menu.children ?? []).filter(item => item.isVisible);

        return children.length > 0 ? { ...menu, children } : menu;
      });
  }

  setWidth(width: number, index: number): void {
    if (this._roles && this._visibleMenuItems[index]) {
      this._visibleMenuItems[index].width = width;
      this.emitWhenReady();
    }
  }

  resetItemWidths(isHomeVisible = true): void {
    this._visibleMenuItems = this.getVisibleMenuItems(isHomeVisible);
    this.emitWhenReady();
  }

  setRoles(roles: ERole[], isHomeVisible = true): void {
    this._roles = roles;
    this._visibleMenuItems = this.getVisibleMenuItems(isHomeVisible);
    this.emitWhenReady();
  }

  setWindowWidth(windowWidth: number): void {
    this._windowWidth = windowWidth;
    this.emitWhenReady();
  }
  private emitWhenReady(): void {
    if (this.isReadyForEmit()) {
      this.emitSplit();
    }
  }

  private isReadyForEmit(): boolean {
    const everyItemHasWidth = this._visibleMenuItems.every(item => item.width);
    const rolesAreSet = this._roles !== null;
    const windowsWidthIsSet = this._windowWidth > -1;

    return rolesAreSet && windowsWidthIsSet && (this.noItemHasWidth() || everyItemHasWidth);
  }

  private noItemHasWidth(): boolean {
    return !this._visibleMenuItems.some(item => item.width);
  }

  private emitSplit(): void {
    this.splitSubject$.next(this.calculateSplit());
  }

  private calculateSplit(): ResponsiveMenuSplit {
    let menuItems: MeasureableMenuItem[] = [...this._visibleMenuItems];

    if (this.noItemHasWidth()) {
      return {
        visibleMenuItems: menuItems,
        hiddenMenuItems: [],
      };
    }

    let widthRequired = ResponsiveMenuService.calculateRequiredWidth(menuItems);

    while (!this.fitsToScreen(widthRequired)) {
      menuItems = menuItems.slice(0, -1);
      widthRequired = ResponsiveMenuService.calculateRequiredWidth(menuItems);
    }

    return {
      visibleMenuItems: menuItems,
      hiddenMenuItems: this._visibleMenuItems.slice(menuItems.length),
    };
  }

  private fitsToScreen(widthRequired: number): boolean {
    return widthRequired + this.OFFSET + this.ABBREVIATION_MENU_ITEM_SIZE <= this._windowWidth;
  }

  private isNavbarItemVisible(navbarItem: ENavbarItem, feature?: FeatureFlag): boolean {
    if (feature && !this.featureService.isActive(feature)) {
      return false;
    }

    return hasObjectReadAccess(navbarItem, this._roles);
  }

  private getCoordinationItems(): MeasureableMenuItem[] {
    return isGrpUser(this._roles)
      ? [
          {
            name: 'coordination-app-home',
            innerHTML: this.translateService.instant('navigation.menu-main.grp.coordination'),
            targetApp: App.Coordination,
            urlPath: URL_PATHS.COORDINATION_APP_OVERVIEW,
            isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION),
            ignoreActive: [
              { targetApp: App.Coordination, urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES },
              { targetApp: App.Coordination, urlPath: URL_PATHS.PENDING_COMPLETION_CODES_OVERVIEW },
            ],
          },
          {
            name: 'coordination-manual-completion',
            innerHTML: this.translateService.instant('navigation.menu-main.coordination.grp.manual-completion'),
            targetApp: App.Coordination,
            urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES,
            isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION_IMPORT),
            ignoreActive: [{ targetApp: App.Coordination, urlPath: URL_PATHS.PENDING_COMPLETION_CODES_OVERVIEW }],
            children: [
              {
                name: 'coordination-app-import-vehicles',
                innerHTML: this.translateService.instant('navigation.menu-main.coordination.import-vehicles'),
                targetApp: App.Coordination,
                urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES,
                isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION_IMPORT_VEHICLES),
              },
              {
                name: 'pending-completion-codes-overview',
                innerHTML: this.translateService.instant('navigation.menu-main.pending-completion-codes'),
                targetApp: App.Coordination,
                urlPath: URL_PATHS.PENDING_COMPLETION_CODES_OVERVIEW,
                isVisible: this.isNavbarItemVisible(ENavbarItem.PENDING_COMPLETION_CODES),
                ignoreActive: [
                  { targetApp: App.Coordination, urlPath: URL_PATHS.ADMIN_PANEL },
                  { targetApp: App.Coordination, urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES },
                ],
              },
            ],
          },
        ]
      : [
          {
            name: 'coordination-app-home',
            innerHTML: this.translateService.instant('navigation.menu-main.coordination'),
            targetApp: App.Coordination,
            urlPath: URL_PATHS.COORDINATION_APP_OVERVIEW,
            isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION),
            ignoreActive: [
              { targetApp: App.Coordination, urlPath: URL_PATHS.PENDING_COMPLETION_CODES_OVERVIEW },
              { targetApp: App.Coordination, urlPath: URL_PATHS.ADMIN_PANEL },
            ],
            children: [
              {
                name: 'coordination-app-overview',
                innerHTML: this.translateService.instant('navigation.menu-main.coordination.overview'),
                targetApp: App.Coordination,
                urlPath: URL_PATHS.COORDINATION_APP_OVERVIEW,
                isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION),
              },
              {
                name: 'coordination-app-import-vehicles',
                innerHTML: this.translateService.instant('navigation.menu-main.coordination.import-vehicles'),
                targetApp: App.Coordination,
                urlPath: URL_PATHS.COORDINATION_APP_IMPORT_VEHICLES,
                isVisible: this.isNavbarItemVisible(ENavbarItem.COORDINATION_IMPORT_VEHICLES),
              },
            ],
          },
        ];
  }
}
