export class App {
  static readonly Campaigns = new App('campaigns', 'campaigns');
  static readonly Committee = new App('committee', 'committee');
  static readonly Consent = new App('consent', 'consent');
  static readonly Preliminary = new App('preliminary', 'preliminary');
  static readonly Topics = new App('topics', 'topic');
  static readonly UserManagement = new App('user-management', 'user-management');
  static readonly PartnerData = new App('partner-data', 'partner-data');
  static readonly Coordination = new App('coordination', 'coordination');
  static readonly ConsumerManagement = new App('consumer-management', 'consumer-management');

  private constructor(
    public readonly id: string,
    public readonly urlPrefix: string,
  ) {}
}
