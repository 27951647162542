import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseSmall, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';

import { NotificationIconComponent } from './components/notification-icon/notification-icon.component';
import { NotificationType } from './enums/notification.enum';
import { NotificationContent } from './models/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NotificationIconComponent, NgClass, NgIf, SVGIconComponent, NgTemplateOutlet, TranslateModule],
})
export class NotificationComponent {
  @HostBinding('class.success')
  get isSuccess(): boolean {
    return this.notification.type === NotificationType.Success;
  }

  @HostBinding('class.warning')
  get isWarning(): boolean {
    return this.notification.type === NotificationType.Warning;
  }

  @HostBinding('class.error')
  get isError(): boolean {
    return this.notification.type === NotificationType.Error;
  }

  @HostBinding('class.info')
  get isInfo(): boolean {
    return this.notification.type === NotificationType.Info;
  }

  @HostBinding('class.multiline')
  get isMultiline(): boolean {
    return !!this.notification.title && !!this.notification.message;
  }

  @Input() notification: NotificationContent;
  @Input() customTemplate: TemplateRef<unknown>;
  @Input() showIcon = true;
  @Input() isClosable = true;
  @Output() closed = new EventEmitter<void>();

  constructor(private iconsRegistry: SVGIconsRegistry) {
    this.iconsRegistry.registerIcons([iconCloseSmall]);
  }

  onClose(event: MouseEvent): void {
    event.stopPropagation();

    this.closed.emit();
  }
}
