export const toBlob = (base64String: string): Blob => {
  const decoded = decodeBase64String(base64String);
  const arrayBuffer = toArrayBuffer(decoded);
  return new Blob([arrayBuffer], { type: 'application/octet-stream' });
};

export const download = (blob?: Blob, filename?: string, url?: string): void => {
  const a = document.createElement('a');
  (a as any).style = 'display: none';
  document.body.append(a);
  const downloadUrl = blob ? window.URL.createObjectURL(blob) : url;
  a.href = downloadUrl;
  if (filename) {
    a.download = filename;
  }

  a.addEventListener('click', event => event.stopPropagation());
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

const decodeBase64String = (value: string): string => {
  return atob(value);
};

const toArrayBuffer = (input: string): ArrayBuffer => {
  const view = new Uint8Array(input.length);
  for (let i = 0, strLen = input.length; i < strLen; i++) {
    view[i] = input.charCodeAt(i);
  }
  return view.buffer;
};
