import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type { NavigationExtras } from '@angular/router';
import { Router } from '@angular/router';
import { WINDOW } from '@recall2/globals';

import { EXTERNAL_URL } from '../../external-url/external-url';
import { App } from '../models/app';
import type { FullyQualifiedUrl } from '../models/fully-qualified-url';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private currentApp: App;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly location: Location,
    private readonly router: Router,
  ) {
    this.setCurrentApp();
  }

  navigate(fullyQualifiedUrl: FullyQualifiedUrl): Promise<boolean> {
    const absoluteUrl = this.getAbsoluteUrl(fullyQualifiedUrl);

    if (this.currentApp === fullyQualifiedUrl.app && !fullyQualifiedUrl.openNewTab) {
      const appUrl = absoluteUrl.split(`${this.window.location.origin}/${this.currentApp.urlPrefix}`)[1] || '/';
      return this.router.navigateByUrl(appUrl);
    }

    if (fullyQualifiedUrl.openNewTab) {
      this.window.open(absoluteUrl, '_blank');
      return Promise.resolve(true);
    }

    return this.navigateToExternalUrl(absoluteUrl, fullyQualifiedUrl.navigationExtras);
  }

  getCurrentApp(): App {
    return this.currentApp;
  }

  getAppBaseHref(app: App): string {
    return `${this.window.location.origin}/${app.urlPrefix}`;
  }

  getAbsoluteUrl({ app, path, queryParams }: FullyQualifiedUrl): string {
    let url = this.getAppUrl(app, path);

    if (queryParams) {
      const params = Object.entries(queryParams)
        .reduce((prev, [key, value]) => {
          prev.push(`${key}=${value}`);
          return prev;
        }, [])
        .join('&');

      url = `${url}?${params}`;
    }

    return url;
  }

  isCurrentUrlAppChildRoute(app: App, urlPath?: string): boolean {
    return this.window.location.href.startsWith(this.getAppUrl(app, urlPath));
  }

  private getAppUrl(app: App, urlPath?: string | string[]): string {
    const url = `${this.getAppBaseHref(app)}${urlPath || ''}`;
    // Some url paths end with a '/' character
    // They are spread over the component templates for using the recall2AppsLink directive
    return this.location.normalize(url);
  }

  private setCurrentApp(): void {
    const apps = Object.values(App) as App[];
    const currentApp = apps.find(app => this.isCurrentUrlAppChildRoute(app));
    if (!currentApp) {
      console.error('Failed to get current app. Check the "appsBaseHref" configuration');
    }
    this.currentApp = currentApp;
  }

  /**
   * It will notify Angular that the user wants to navigate to a url outside the defined routes inside the app
   * (avoid `window.location.href`). Any Guard will be able to capture this event and act accordingly, for instance,
   * to display the "Unsaved pending changes" modal.
   */
  private navigateToExternalUrl(url: string, navigationExtras: NavigationExtras = {}): Promise<boolean> {
    return this.router.navigate(['/', EXTERNAL_URL], {
      ...navigationExtras,
      state: {
        ...navigationExtras.state,
        externalUrl: url,
      },
    });
  }
}
