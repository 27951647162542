import { NgFor } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { AuthUserType } from '../../../auth/models/auth-user-type.enum';
import { AuthUserQuery } from '../../../auth/store/user/auth-user.query';
import { Recall2ButtonTertiaryComponent } from '../../../buttons/components/recall2-button-tertiary/recall2-button-tertiary.component';
import type { SupportedLanguages } from '../../../i18n';
import { AppTranslationService, LanguageCode } from '../../../i18n';
import type { IUser } from '../../models/user';
import { LanguageSelectorService } from '../../services/language-selector.service';

@Component({
  selector: 'recall2-language-selector-modal',
  templateUrl: './language-selector-modal.component.html',
  styleUrls: ['language-selector-modal.component.scss'],
  standalone: true,
  imports: [SVGIconComponent, NgFor, Recall2ButtonTertiaryComponent, TranslateModule],
})
export class LanguageSelectorModalComponent implements OnInit, OnDestroy {
  languages: SupportedLanguages[] = [LanguageCode.EnglishGB, LanguageCode.Spanish, LanguageCode.German];
  isGrpUser = false;
  private readonly destroy$ = new Subject<void>();
  constructor(
    public iconsRegistry: SVGIconsRegistry,
    private readonly dialogRef: MatDialogRef<LanguageSelectorModalComponent>,
    private readonly languageSelectorService: LanguageSelectorService,
    private readonly appTranslationService: AppTranslationService,
    private readonly authUserQuery: AuthUserQuery,
  ) {
    iconsRegistry.registerIcons([iconCloseMedium]);
  }

  ngOnInit(): void {
    this.isGrpUser = this.authUserQuery?.user?.userIdentifier?.userType === AuthUserType.grp;
  }

  onChangeLanguage($event: Event, lang: SupportedLanguages): void {
    $event.stopPropagation();
    this.appTranslationService.switchTo(lang);
    sessionStorage.setItem('userLanguage', lang);

    if (this.isGrpUser) {
      this.dialogRef.close(true);
      return;
    }

    this.languageSelectorService
      .getUser$()
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user),
        switchMap((user: IUser) => {
          return this.languageSelectorService.setUser$(user.id, { ...user, language: lang });
        }),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onClose($event: Event): void {
    $event.stopPropagation();
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
