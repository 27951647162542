import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { iconArrowDownMedium, iconArrowUpMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';

@Component({
  selector: 'recall2-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [SVGIconComponent, NgIf, NgTemplateOutlet],
})
export class CardComponent {
  @Input() isExpandable = false;
  @Input() isExpanded = false;
  @Output() expand = new EventEmitter<boolean>();

  constructor(private iconsRegistry: SVGIconsRegistry) {
    this.iconsRegistry.registerIcons([iconArrowDownMedium, iconArrowUpMedium]);
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.expand.emit(this.isExpanded);
  }
}
