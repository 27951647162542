import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { NotificationLevel } from '@recall2/notifications';
import { NotificationsService } from '@recall2/notifications';

import { NotificationType } from '../../../notification/enums/notification.enum';
import type { ToastType } from '../../models/toast.model';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationsRendererService {
  private readonly levelToType: Record<NotificationLevel, ToastType> = {
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
    DOWNLOAD: 'download',
  };

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {}

  subscribeToNotifications(): void {
    this.notificationsService.notifications$.subscribe(notification => {
      if (this.mapNotificationLevelToToastType(notification.level) !== NotificationType.Download) {
        this.toastService.message(this.mapNotificationLevelToToastType(notification.level), {
          title: this.translateService.instant(notification.titleMessageKey),
          content: this.translateService.instant(notification.contentMessageKey, notification.contentMessageParameters),
        });
      }
    });
  }

  private mapNotificationLevelToToastType(level: NotificationLevel): ToastType {
    return this.levelToType[level];
  }
}
