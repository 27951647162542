import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconCheckMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';

import { Recall2ButtonPrimaryComponent } from '../../../buttons';
import { Recall2LoadingSpinnerComponent } from '../../../loading-spinner';
import { getValueFromObject } from '../../../utils/functions/functions';
import { Recall2AutoSuggestHighlightDirective } from '../../directives/recall2-auto-suggest-highlight.directive';
import { EAutoSuggestObjectType } from '../../models/auto-suggest-object-type.enum';

@Component({
  selector: 'recall2-auto-suggest-list',
  templateUrl: './recall2-auto-suggest-list.component.html',
  styleUrls: ['./recall2-auto-suggest-list.component.scss'],
  standalone: true,
  imports: [
    Recall2LoadingSpinnerComponent,
    Recall2ButtonPrimaryComponent,
    SVGIconComponent,
    Recall2AutoSuggestHighlightDirective,
    MatTooltipModule,
    TranslateModule,
    NgIf,
    NgFor,
    NgClass,
  ],
})
export class Recall2AutoSuggestListComponent {
  @ViewChild('autoSuggestList') autoSuggestList: ElementRef<HTMLElement>;
  @ViewChildren('checkSelectedItems') checkSelectedItems: QueryList<ElementRef<HTMLElement>>;

  @Input() entries: any[] = [];
  @Input() isSpinnerShowing = false;
  @Input() isArrowDown = false;
  @Input() searchTerm = '';
  @Input() objectType: EAutoSuggestObjectType;
  @Input() searchDirectoryEnabled = false;
  @Input() isCheckSelectedItems = false;
  @Input() showButtons = true;

  @Output() selectEntry = new EventEmitter<any>();
  @Output() directorySearch = new EventEmitter<void>();

  readonly userTypeValue = EAutoSuggestObjectType.USER;

  constructor(svgIconRegistry: SVGIconsRegistry) {
    svgIconRegistry.registerIcons([iconCheckMedium]);
  }

  onDirectorySearch(): void {
    this.directorySearch.emit();
  }

  onKeyDownForList(): void {
    const firstElement = this.autoSuggestList.nativeElement.firstElementChild as HTMLElement;
    if (firstElement) {
      firstElement.focus();
    }
  }

  onClick(event: MouseEvent, entry: unknown, index: number): void {
    if (this.isCheckSelectedItems) {
      this.toggleCheckmark(index);
    }

    event.stopPropagation();

    if (this.isEntryDisabled(entry)) {
      return;
    }

    this.selectEntry.emit(entry);
  }

  onKeyDownForElement(event: KeyboardEvent, element: unknown, index: number): void {
    event.stopPropagation();
    event.preventDefault();

    let next;
    let isNextDisabled = false;

    switch (event.key) {
      case 'ArrowDown': {
        next = getValueFromObject(element, 'nextElementSibling');
        const nextElementIndex = index + 1;

        if (next && this.isEntryDisabled(this.entries[nextElementIndex])) {
          isNextDisabled = true;
          this.onKeyDownForElement(event, next, nextElementIndex);
        }

        break;
      }

      case 'ArrowUp': {
        next = getValueFromObject(element, 'previousElementSibling');
        const previousElementIndex = index - 1;

        if (next && this.isEntryDisabled(this.entries[previousElementIndex])) {
          isNextDisabled = true;
          this.onKeyDownForElement(event, next, previousElementIndex);
        }

        break;
      }

      case 'Enter': {
        if (!this.isEntryDisabled(this.entries[index])) {
          this.selectEntry.emit(this.entries[index]);
        }
        break;
      }
      default: {
        break;
      }
    }

    if (next && !isNextDisabled) {
      next.focus();
    }
  }

  private isEntryDisabled(entry: any): boolean {
    return this.objectType === this.userTypeValue && !entry.email;
  }

  private toggleCheckmark(index: number): void {
    this.checkSelectedItems.forEach((checkmark, i) => {
      const action = index === i ? 'add' : 'remove';
      checkmark.nativeElement.classList[action]('checkmark-show');
    });
  }
}
