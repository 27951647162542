import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import type { ElementRef, OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseChipSmall, iconDeleteMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../../overlay/models/filter.model';
import { EDateFilterComparator, EFilterTemplates } from '../../../overlay/models/filter.model';
import { NumberFilterComparatorTranslateKeyPipe } from '../../../overlay/pipes/number-filter-comparator-translate-key/number-filter-comparator-translate-key.pipe';
import { CommaListPipe, UserRolesPipe } from '../../../pipes';
import { FilterOptionDateKeyPipe } from '../../../pipes/filter-option-date-key/filter-option-date-key.pipe';
import { FilterAutocompleteOptionTranslationKeyPipe } from '../../pipes/filter-autocomplete-option-translation-key/filter-autocomplete-option-translation-key.pipe';
import { FilterOptionTranslationKeyPipe } from '../../pipes/filter-option-translation-key/filter-option-translation-key.pipe';
import { Recall2TableFilterOverlayService } from '../../services/recall2-table-filter-overlay.service';
import type { TableService } from '../../services/table.service';
import { TableServiceFactory } from '../../services/table-factory.service';

const FILTER_OVERLAY_OFFSET = { offsetX: 0, offsetY: 8 };

@Component({
  selector: 'app-active-filters',
  templateUrl: './active-filters.component.html',
  styleUrls: ['./active-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    SVGIconComponent,
    MatTooltipModule,
    CommaListPipe,
    NumberFilterComparatorTranslateKeyPipe,
    UserRolesPipe,
    FilterOptionTranslationKeyPipe,
    FilterAutocompleteOptionTranslationKeyPipe,
    NgIf,
    NgFor,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgClass,
    FilterOptionDateKeyPipe,
  ],
  providers: [DatePipe],
})
export class ActiveFiltersComponent implements OnDestroy {
  @Input() filtersConfig: Recall2FilterV2Config[] = [];
  @Input() set tableId(tableKey: string) {
    this.tableKey = tableKey;
    this.tableService = this.tableFactory.get(tableKey);
    this.activeFilters$ = this.tableService.activeFilters$.pipe(takeUntil(this.destroy$));
  }

  @ViewChildren('activeFilterElement') activeFilterElements: QueryList<ElementRef>;
  @ViewChildren('activeFiltersTextContainer') activeFiltersTextContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersNumberContainer') activeFiltersNumberContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersDateContainer') activeFiltersDateContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersYearContainer') activeFiltersYearContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersAutocompleteContainer') activeFiltersAutocompleteContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersUserRolesContainer') activeFiltersUserRolesContainer: QueryList<ElementRef>;
  @ViewChildren('activeFiltersDefaultContainer') activeFiltersDefaultContainer: QueryList<ElementRef>;

  tableKey: string;
  tableService: TableService;
  activeFilters$: Observable<IRecall2FilterParam[]>;
  EDateFilterComparator = EDateFilterComparator;
  EFilterTemplates = EFilterTemplates;

  filterOpenIndex: number;

  private destroy$ = new Subject<void>();

  constructor(
    private iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
    private filterOverlayService: Recall2TableFilterOverlayService,
    private tableFactory: TableServiceFactory,
  ) {
    this.iconsRegistry.registerIcons([iconCloseChipSmall, iconDeleteMedium]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getActiveFilterContainer(activeFilterType: EFilterTemplates): string {
    switch (activeFilterType) {
      case EFilterTemplates.TEXT: {
        return 'activeFiltersTextContainer';
      }
      case EFilterTemplates.NUMBER: {
        return 'activeFiltersNumberContainer';
      }
      case EFilterTemplates.DATE: {
        return 'activeFiltersDateContainer';
      }
      case EFilterTemplates.YEAR: {
        return 'activeFiltersYearContainer';
      }
      case EFilterTemplates.AUTOCOMPLETE: {
        return 'activeFiltersAutocompleteContainer';
      }
      case EFilterTemplates.USER_ROLES: {
        return 'activeFiltersUserRolesContainer';
      }
      default: {
        return 'activeFiltersDefaultContainer';
      }
    }
  }

  propagateHoverThroughElements(
    activeFilter: IRecall2FilterParam,
    index: number,
    action: 'mouseenter' | 'mouseleave',
  ): void {
    const mouseEvent = new Event(action);
    const containerId = this.getActiveFilterContainer(activeFilter.type);
    const container = this[containerId];

    container
      .find((val: ElementRef) => val.nativeElement.id === `${containerId}-${index}`)
      .nativeElement.dispatchEvent(mouseEvent);
  }

  onRemoveFilter(event: MouseEvent, key: string): void {
    event.stopPropagation();
    this.tableService.removeFilter(key);
  }

  onClearFilters(): void {
    this.tableService.clearFilters();
  }

  onOpenFilter(filterParam: IRecall2FilterParam, htmlElement: HTMLDivElement, index: number): void {
    if (filterParam.type === EFilterTemplates.USER_ROLES) {
      return;
    }
    const elementRefs = this.activeFilterElements.toArray();
    const filtersConfiguration = this.filtersConfig.find(
      (config: Recall2FilterV2Config) => config.identifier === filterParam.identifier,
    );

    this.setOpenFilter(index);

    this.filterOverlayService
      .openFilter(filtersConfiguration, filterParam, htmlElement, elementRefs[index], FILTER_OVERLAY_OFFSET)
      .pipe(
        take(1),
        tap(() => this.setOpenFilter(null)),
        filter(filterChange => !!filterChange),
      )
      .subscribe(currentFilter => this.tableService.addFilter(currentFilter, true));
  }

  private setOpenFilter(activeFilterIndex: number): void {
    this.filterOpenIndex = activeFilterIndex;
    this.cdr.detectChanges();
  }
}
