import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SVGIconComponent } from '@recall2/icons';

import { Recall2ToggleElementComponent } from './components/recall2-toggle-element/recall2-toogle-element.component';

@Component({
  selector: 'recall2-buttons-toggle-group',
  templateUrl: './recall2-buttons-toggle-group.component.html',
  styleUrls: ['./recall2-buttons-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonToggleModule, SVGIconComponent, NgClass, NgIf, NgFor],
})
export class Recall2ButtonsToggleGroupComponent {
  @Input() selectedValue = '';
  @Input() isRangeSwitcherMode = false;
  @Output() selectedToggleButton = new EventEmitter<string>();

  @ContentChildren(Recall2ToggleElementComponent, { descendants: true })
  toggleElements: QueryList<Recall2ToggleElementComponent>;

  onChangeValue(value: string): void {
    this.selectedValue = value;
    this.selectedToggleButton.emit(value);
  }
}
