import { NgIf } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WINDOW } from '@recall2/globals';
import { iconCarMedium, iconStrokeMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { vinSearchRoles } from '../auth';
import {
  Recall2IconCloseComponent,
  Recall2IconConfidentialComponent,
  Recall2IconInfoComponent,
  Recall2IconLogoRecallComponent,
} from '../icons';
import type { ERole } from '../navbar/models/role.model';
import { URL_PATHS } from '../navbar/url-paths';
import { AppsLinkDirective } from '../navigation/components/apps-link/apps-link.directive';
import { App } from '../navigation/models/app';
import { LanguageSelectorModalComponent } from './components/language-selector-modal/language-selector-modal.component';
import { MyDataMenuComponent } from './components/my-data-menu/my-data-menu.component';
import { LogoutService } from './services/logout.service';

@Component({
  selector: 'recall2-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    Recall2IconLogoRecallComponent,
    Recall2IconInfoComponent,
    Recall2IconConfidentialComponent,
    Recall2IconCloseComponent,
    AppsLinkDirective,
    NgIf,
    SVGIconComponent,
    MyDataMenuComponent,
    TranslateModule,
    MatDialogModule,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hideProfile: boolean;
  @Input() roles: ERole[];

  readonly WELCOME_URL = `${URL_PATHS.NOTIFICATION_APP_WELCOME}`;
  readonly APPS = App;
  readonly VIN_SEARCH_URL = `${URL_PATHS.CAMPAIGN_VINS_SEARCHER}`;
  readonly LOGOUT_URL = `${this.window.location.origin}/api-preliminary-user/logout-user`;
  language: string;

  hasVinSearchPermissions = false;
  hasRoles = false;

  private readonly destroy$ = new Subject<void>();
  constructor(
    @Inject(WINDOW) private window: Window,
    private iconsRegistry: SVGIconsRegistry,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private logoutService: LogoutService,
  ) {
    this.iconsRegistry.registerIcons([iconStrokeMedium, iconCarMedium]);
  }

  ngOnInit(): void {
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(language => (this.language = language.lang));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roles']) {
      const newRoles: ERole[] = changes['roles'].currentValue;
      this.hasVinSearchPermissions = vinSearchRoles.some(role => newRoles?.includes(role));
      this.hasRoles = newRoles?.length > 0;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openLanguageSelectorModal(): void {
    this.dialog.open(LanguageSelectorModalComponent, {
      panelClass: 'language-selector-modal',
      backdropClass: 'modal-backdrop',
      width: '620px',
      minHeight: '240px',
    });
  }

  onLogout(): void {
    this.logoutService.logout();
    this.window.location.href = this.LOGOUT_URL;
  }
}
